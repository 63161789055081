import { NgModule } from '@angular/core';
import { HeaderComponent } from './header.component';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import {NgxPopperModule} from 'ngx-popper';
import { MatMenuModule } from '@angular/material/menu';
import { IonicSelectableModule } from 'ionic-selectable';

@NgModule({
  imports: [IonicModule, RouterModule, TranslateModule, FormsModule, CommonModule, NgxPopperModule, MatMenuModule, IonicSelectableModule],
  declarations: [HeaderComponent],
  exports: [HeaderComponent, NgxPopperModule,  IonicSelectableModule]
})
export class HeaderModule {}
