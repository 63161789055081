import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class BlockresetpageGuard implements CanActivate {
  
    constructor(private router: Router, public _commonService: CommonService, private _authService: AuthService) { }

  canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

      // Allow acces to reset page if tempAuthKey is not null
      if (this._commonService.tempAuthKey) {
        return true;
      }
      else {
        this.router.navigate(['login']);
        this._commonService.isLoggedIn = false;
        return false;
      }
    }

}
