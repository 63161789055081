import { Directive, ElementRef, HostBinding } from '@angular/core';

@Directive({
  selector: '[no-margin]'
})
export class NoMarginDirective {

  @HostBinding('style.margin') private margin = '0px'

  constructor(private el: ElementRef) {
    this.margin = '0px';
  }

}
